import { Flex, Link, Select, Text } from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import { DashboardChargeList } from "./charge-list";
import { useEffect, useState } from "react";
import { ChargeMetrics, DashboardChargeData } from "@/models";

interface DashboardChargeListProps {
  charges: Array<DashboardChargeData>;
  chargeMetrics: ChargeMetrics;
}

export function DashboardCharge({
  charges,
}: DashboardChargeListProps) {
  const [chargesFiltered, setChargesFiltered] =
    useState<Array<DashboardChargeData>>(charges);
  const [chargeListFilter, setChargeListFilter] = useState("ALL");

  function handleFilterChargesArray() {
    setChargesFiltered(
      charges.filter((charge) => {
        if (chargeListFilter === "ALL") return charge;
        else return charge.status === chargeListFilter;
      })
    );
  }

  useEffect(() => {
    handleFilterChargesArray();
  }, [chargeListFilter, charges]);

  return (
    <Flex w="100%" h="100%" flexDir="column" >
      <Flex h="10%" w="100%" p="0 1rem" align="center" justify="space-between">
        <Flex>
          <Text marginRight="1rem">Recebíveis</Text>
          <Link href="/cobrancas" textDecoration="underline">
            ver todos
          </Link>
        </Flex>
        <Select
          w="35%"
          variant="ghost"
          bgColor="gray.49"
          color="black"
          icon={<MdArrowDropDown />}
          zIndex={1}
          onChange={(e) => setChargeListFilter(e.target.value)}
          data-testid="dash-test-select-charge-filter"
          defaultValue="ALL"
          mt="4px"
        >
          <option value="ALL">Todas</option>
          <option value="PAID">Pagas</option>
          <option value="LATE">Atrasadas</option>
          <option value="SCHEDULED">Agendadas</option>
        </Select>
      </Flex>
      <Flex h="100%" w="100%" overflowY="scroll">
        {chargesFiltered && <DashboardChargeList charges={chargesFiltered} />}
      </Flex>
    </Flex>
  );
}
