import { Value } from "@/layouts";
import { Redeems } from "@/models";
import { MoneyIn } from "@/styles/icons/MoneyIn";
import { MoneyOut } from "@/styles/icons/MoneyOut";
import { Wallet2Icon } from "@/styles/icons/Wallet2Icon";
import { formatDate, getColorBadgeStatus, getStatus } from "@/utils";
import {
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Tooltip,
  Box,
  Badge,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export interface RedeemHistoryProps {
  redeems: Redeems[];
}
function RedeemHistory({ redeems }: RedeemHistoryProps) {
  const navigate = useNavigate();
  return (
    <Flex>
      <Table
        boxShadow="1px 2px 2px 1px rgba(0, 0, 0, 0.1)"
        borderTopRightRadius=".5rem"
        borderTopLeftRadius=".5rem"
        overflow="hidden"
      >
        <Thead>
          <Tr>
            <Th
              borderTopLeftRadius=".5rem"
              my="4"
              color="gray.300"
              fontSize="14px"
              fontWeight="normal"
            >
              <Flex 
                align="center"
                gap="2"
              >
                Execução
                <Tooltip
                  placement="top" 
                  hasArrow 
                  label="A data da operação é quando o pagamento é compensado"
                >
                  <span>
                    <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
                  </span>
                </Tooltip>
              </Flex>
            </Th>
            <Th my="4" color="gray.300" fontSize="14px" fontWeight="normal">
              Operação
            </Th>
            <Th
              borderTopRightRadius=".5rem"
              my="4"
              color="gray.300"
              fontSize="14px"
              fontWeight="normal"
            >
              Destino
            </Th>
            <Th
              borderTopRightRadius=".5rem"
              my="4"
              color="gray.300"
              fontSize="14px"
              fontWeight="normal"
            >
              Taxas
            </Th>
            <Th
              borderTopRightRadius=".5rem"
              my="4"
              color="gray.300"
              fontSize="14px"
              fontWeight="normal"
            >
              Valor
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {redeems?.length === 0 && (
            <Tr
              display="flex"
              minHeight={600}
              w={`${100 * 2.3}%`}
              alignItems="center"
              justifyContent="center"
            >
              <Flex
                maxW="355px"
                w="100%"
                h="100px"
                border="1px solid #C3CAD7"
                borderRadius=".5rem"
                align="center"
                gap="4"
                p="10"
              >
                <IoAlertCircleOutline color="#FAAD14" size={40} />
                <Text color="gray.400">
                  Você não possui transações no período selecionado
                </Text>
              </Flex>
            </Tr>
          )}
          {redeems?.length > 0 &&
            redeems?.map(
              (
                {
                  executionDate,
                  statementType,
                  isChargeback,
                  isRefund,
                  buyer,
                  amount,
                  creditOperation,
                  operation,
                  tax,
                  uuidCharge,
                  providerType,
                  subSellers
                },
                index
              ) => (
                <React.Fragment key={index}>
                  <Tr
                    key={index}
                    onClick={() => { if (uuidCharge) return window.open(`/cobrancas/${uuidCharge}`, '_blank') }}
                    _hover={{
                      cursor: uuidCharge ? "pointer" : "",
                      td: {
                        bgColor: statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.8)" : "gray.25",
                      },
                    }}
                  >
                    <Td maxW="50px" bgColor={statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.5);" : "white"}>
                      <Flex alignItems="center" gap="2">
                        {(creditOperation && statementType !== "DEBIT_TRANSFER") && <MoneyIn size={24} color={isRefund ? "#8A94A8" : "#389E0D"}/>}
                        {(!creditOperation && statementType !== "DEBIT_TRANSFER") && <MoneyOut size={24} />}
                        {statementType === "DEBIT_TRANSFER" && <Wallet2Icon size={24} />}
                        {formatDate(executionDate)}
                      </Flex>
                    </Td>
                    <Td maxW="150px" bgColor={statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.5);" : "white"}>
                      <Flex direction="column" gap="1">
                        <Flex gap="2">
                          {isRefund && (
                            <Badge
                              fontSize=".9rem"
                              variant={getColorBadgeStatus("CHARGEBACK_LIST")}
                            >
                              {getStatus("REFUND")}
                            </Badge>
                          )}
                          {isChargeback && (
                            <Badge
                              fontSize=".9rem"
                              variant={getColorBadgeStatus("CHARGEBACK_LIST")}
                            >
                              {getStatus("CHARGEBACK")}
                            </Badge>
                          )}
                          {statementType.includes("CREDIT_REVERSAL") && (
                            <Badge
                              fontSize=".9rem"
                              variant={getColorBadgeStatus("CHARGEBACK_LIST")}
                            >
                              Estorno
                            </Badge>
                          )}
                          <Tooltip label={operation}>
                            <Text noOfLines={1}>{operation}</Text>
                          </Tooltip>
                        </Flex>
                        {buyer && (
                          <Tooltip label={buyer}>
                            <Text fontSize="12px" noOfLines={1} color="gray.500">{buyer}</Text>
                          </Tooltip>
                        )}
                      </Flex>
                    </Td>
                    <Td bgColor={statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.5);" : "white"}>
                      {statementType.includes("DEBIT_TRANSFER") ? "" : (providerType ?? "-")}
                    </Td>
                    <Td bgColor={statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.5);" : "white"}>
                    {tax && (
                      <Value
                        isChargeback={isChargeback}
                        isRefund={isRefund}
                        amount={tax?.amount}
                        creditOperation={tax?.creditOperation}
                        isTax
                      />
                    )}
                    </Td>
                    <Td bgColor={statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.5);" : "white"}>
                      <Flex direction="column" gap="4">
                        <Value
                          isChargeback={isChargeback}
                          isRefund={isRefund}
                          amount={amount}
                          creditOperation={creditOperation}
                          statementType={statementType}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                  {subSellers?.length > 0 && subSellers?.map(subSeller => (
                  <Tr
                    key={index}
                    onClick={() => { if (subSeller?.uuidCharge) return window.open(`/cobrancas/${subSeller?.uuidCharge}`, '_blank') }}
                    _hover={{
                      cursor: subSeller?.uuidCharge ? "pointer" : "",
                      td: {
                        bgColor: subSeller?.statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.8)" : "gray.25",
                      },
                    }}
                  >
                    <Td maxW="50px" bgColor={subSeller?.statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.5);" : "white"}>
                      <Flex alignItems="center" gap="2">
                        {(subSeller?.creditOperation && subSeller?.statementType !== "DEBIT_TRANSFER") && <MoneyIn size={24} color={subSeller?.isRefund ? "#8A94A8" : "#389E0D"}/>}
                        {(!subSeller?.creditOperation && subSeller?.statementType !== "DEBIT_TRANSFER") && <MoneyOut size={24} />}
                        {subSeller?.statementType === "DEBIT_TRANSFER" && <Wallet2Icon size={24} />}
                        {formatDate(subSeller?.executionDate)}
                      </Flex>
                    </Td>
                    <Td maxW="150px" bgColor={subSeller?.statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.5);" : "white"}>
                      <Flex direction="column" gap="1">
                        <Flex gap="2">
                          {subSeller?.isRefund && (
                            <Badge
                              fontSize=".9rem"
                              variant={getColorBadgeStatus("CHARGEBACK_LIST")}
                            >
                              {getStatus("REFUND")}
                            </Badge>
                          )}
                          {subSeller?.isChargeback && (
                            <Badge
                              fontSize=".9rem"
                              variant={getColorBadgeStatus("CHARGEBACK_LIST")}
                            >
                              {getStatus("CHARGEBACK")}
                            </Badge>
                          )}
                          {subSeller?.statementType.includes("CREDIT_REVERSAL") && (
                            <Badge
                              fontSize=".9rem"
                              variant={getColorBadgeStatus("CHARGEBACK_LIST")}
                            >
                              Estorno
                            </Badge>
                          )}
                          <Tooltip label={`${subSeller?.operation} - Split - ${subSeller?.name}`}>
                            <Text noOfLines={1}>{`${subSeller?.operation} - Split - ${subSeller?.name}`}</Text>
                          </Tooltip>
                        </Flex>
                        {subSeller?.buyer && (
                          <Tooltip label={subSeller?.buyer}>
                            <Text fontSize="12px" noOfLines={1} color="gray.500">{subSeller?.buyer}</Text>
                          </Tooltip>
                        )}
                      </Flex>
                    </Td>
                    <Td bgColor={subSeller?.statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.5);" : "white"}>
                      {subSeller?.statementType.includes("DEBIT_TRANSFER") ? "" : (subSeller?.providerType ?? "-")}
                    </Td>
                    <Td bgColor={subSeller?.statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.5);" : "white"}>
                    {subSeller?.tax && (
                      <Value
                        isChargeback={subSeller?.isChargeback}
                        isRefund={subSeller?.isRefund}
                        amount={subSeller?.tax?.amount}
                        creditOperation={subSeller?.tax?.creditOperation}
                        isTax
                      />
                    )}
                    </Td>
                    <Td bgColor={subSeller?.statementType.includes("DEBIT_TRANSFER") ? "rgba(217, 235, 230, 0.5);" : "white"}>
                      <Flex direction="column" gap="4">
                        <Value
                          isChargeback={subSeller?.isChargeback}
                          isRefund={subSeller?.isRefund}
                          amount={subSeller?.amount}
                          creditOperation={subSeller?.creditOperation}
                          statementType={subSeller?.statementType}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                  ))}
                </React.Fragment>
              )
            )}
        </Tbody>
      </Table>
    </Flex>
  );
}

export { RedeemHistory };
