import { SellerRegisterData } from "@/models";
import { Api } from "@/services";

export function useRegister() {
  const baseUrl = "/public/sellers";
  const baseUrlV2 = "v2/public/sellers";

  const registerSeller = (data: SellerRegisterData) => {
    return Api.post(`${baseUrl}`, data);
  };

  const registerSellerV2 = (data: any) => {
    return Api.post(`${baseUrlV2}`, data);
  };

  return {
    registerSeller,
    registerSellerV2
  };
}
