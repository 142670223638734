import { ReactNode, KeyboardEvent, useState, useEffect } from "react";
import {
  Flex,
  Input as ChakraInput,
  Switch,
  Text,
  InputProps as ChakraInputProps,
  Tooltip,
  Icon,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  FormControl,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { maskJs } from "mask-js";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  cellPhone,
  cep,
  cnpj,
  cpf,
  currency,
  formatCurrency,
  onlyLetters,
  percentage,
  percentageRacional,
  phone,
  simpleDate,
  fullDate,
  onlyNumbers,
} from "@/utils";

export interface InputProps extends ChakraInputProps {
  type?: string;
  name: string;
  switchActive?: boolean;
  periodicityName?: string;
  containerDisabled?: boolean;
  hasMonthlyValue?: boolean;
  valueData?: any;
  asSubscription?: boolean;
  tooltipText?: string;
  hasSwitch?: boolean;
  label?: string;
  labelColor?: string;
  register?: any;
  setValue?: any;
  errors?: any;
  mask?: string;
  valueLenght?: number;
  classInput?: string;
  before?: string;
  after?: string;
  variant?: string;
  switchFunction?: (any?: any) => void;
  color?: string;
  asDoubleEffect?: boolean;
  optionalText?: string;
  afterFontSize?: string;
  afterColor?: string;
  afterBackgroundColor?: string;
  noMarginOnError?: boolean;
}

export function Input({
  type = "text",
  name,
  setValue,
  switchActive,
  containerDisabled = true,
  periodicityName,
  valueLenght,
  hasMonthlyValue,
  valueData,
  asSubscription,
  tooltipText,
  label,
  labelColor = "gray.600",
  register,
  errors,
  mask,
  hasSwitch = false,
  classInput,
  before,
  after,
  variant,
  switchFunction,
  color,
  asDoubleEffect,
  optionalText,
  afterFontSize = "2xl",
  afterColor = "gray.400",
  afterBackgroundColor = "gray.100",
  noMarginOnError,
  ...rest
}: InputProps) {
  const [disabledState, setDisabledState] = useState(
    hasSwitch || containerDisabled
  );
  const [showPassword, setShowPassword] = useState(false);
  const [typeInput, setTypeInput] = useState(type);

  const handleMaskInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      mask === "cpf/cnpj" ||
      mask === "currency" ||
      mask === "currency(noBrSign)" ||
      mask === "percentage" ||
      mask === "percentageRacional"
    )
      return;
    if (mask === "cep") {
      cep(e);
    } else if (mask === "phone") {
      phone(e);
    } else if (mask === "cpf") {
      cpf(e);
    } else if (mask === "cellphone") {
      cellPhone(e);
    } else if (mask === "cnpj") {
      cnpj(e);
    } else if (mask === "onlyLetters") {
      onlyLetters(e);
    } else if (mask === "onlyNumbers") {
      onlyNumbers(e);
    }
    else if (mask === "simpleDate") {
      simpleDate(e)
    }
    else if (mask === "fullDate") {
      fullDate(e)
    }
    else if (mask) {
      e.currentTarget.value = maskJs(mask, e.currentTarget.value);
    }
  };

  const handleMaskInputCurrency = (e: KeyboardEvent<HTMLInputElement>) => {
    //@ts-ignore
    const isNumber = isFinite(e.key);
    if (mask === "currency" && isNumber) {
      currency(e)
    
    }
    if (mask === "currency(noBrSign)" && isNumber) {
      currency(e, true);
      return;
    }
    if (mask === "percentageRacional") {
      percentageRacional(e);
      return;
    }
  };

  const handleMaskOnBlur = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.includes("-")) return;
    if (mask === "cpf/cnpj") {
      if (e.currentTarget.value.length === 14) return cnpj(e);
      cpf(e);
    }
    if (mask === "percentage") {
      percentage(e);
    }
    if (mask === "percentageRacional") {
      percentageRacional(e, true)
    }
  };

  function handleShowPassword() {
    setShowPassword(!showPassword);
    setTypeInput((prevValue) =>
      prevValue === "password" ? "text" : "password"
    );
  }

  function handleSwitchChange(e: any) {
    if (asDoubleEffect) {
      switchFunction(e);
    }
    setDisabledState(!disabledState);
    setValue ? setValue(name, null) : null;
  }

  const Component: ReactNode = (
    <ChakraInput
      onBlurCapture={handleMaskOnBlur}
      onKeyUp={handleMaskInput}
      onKeyDown={handleMaskInputCurrency}
      disabled={(hasSwitch && disabledState) || !containerDisabled || (asDoubleEffect && !switchActive)}
      type={typeInput}
      name={name}
      borderColor={errors ? "danger" : "newGray.200"}
      border={errors ? "1.5px solid" : "1px solid"}
      borderRadius="4px"
      variant={variant}
      w="100%"
      color={color ? color : "black"}
      _focus={{
        border: "1px solid",
        borderColor: "blue.200",
      }}
      {...register}
      {...rest}
    />
  );

  useEffect(() => {
    setDisabledState(!switchActive);
  }, []);

  return (
    <FormControl isInvalid={!!errors?.message}>
      <Flex flexDir="column">
        <Flex align="center" justify="space-between">
          <Flex align="center" marginRight={tooltipText ? ".2rem" : ""}>
            {label && rest?.visibility !== "hidden" && (
              <Text
                marginRight={tooltipText ? ".2rem" : ""}
                marginBottom=".2rem"
                fontSize=".8rem"
                color={
                  (hasSwitch && disabledState) || !containerDisabled
                    ? "newGray.200"
                    : labelColor}
                cursor={
                  ((hasSwitch && disabledState) || !containerDisabled) &&
                  "not-allowed"
                }
              >
                {label}
              </Text>
            )}
            {optionalText && (
              <Text marginLeft=".2rem" marginBottom=".2rem" fontSize=".8rem" color="#A5AEC0">
                {optionalText}
              </Text>
            )}
            {tooltipText && (
              <Tooltip placement="top" hasArrow label={tooltipText}>
                <span>
                  <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
                </span>
              </Tooltip>
            )}
          </Flex>
          {hasSwitch && !asDoubleEffect && (
            <Switch
              name={name}
              defaultChecked={switchActive}
              onChange={handleSwitchChange}
              size="sm"
            />
          )}

          {hasSwitch && asDoubleEffect && (
            <Switch
              name={name}
              isChecked={switchActive}
              defaultChecked={switchActive}
              onChange={handleSwitchChange}
              size="sm"
            />
          )}
        </Flex>
        <Flex flexDir="column" position="relative">
          <InputGroup>
            {before && (
              <InputLeftAddon fontSize="2xl" color="black" children={before} />
            )}
            {Component}
            {after && (
              <InputRightAddon
                fontSize={afterFontSize}
                color={afterColor}
                children={after}
                backgroundColor={afterBackgroundColor}
              />
            )}
            {type === "password" && (
              <InputRightAddon
                children={
                  <Flex onClick={handleShowPassword} as="button" type="button">
                    {showPassword ? (
                      <FaEye size={16} />
                    ) : (
                      <FaEyeSlash size={16} />
                    )}
                  </Flex>
                }
              />
            )}
          </InputGroup>
          {asSubscription && hasMonthlyValue && (
            <Text
              fontSize="12px"
              color="newGray.300"
              position="absolute"
              top="3"
              right="2"
            >
              {formatCurrency({ value: valueData?.valuePerMonth })}/mês
            </Text>
          )}
        </Flex>
        {asSubscription && (
          <>
            <Flex color="newGray.300" justify="space-between">
              <Text fontSize="10px">
                Valor original:{" "}
                {formatCurrency({ value: valueData?.originalValue })}/
                {periodicityName}
              </Text>
              {hasMonthlyValue && (
                <Text fontSize="10px">
                  {formatCurrency({ value: valueData?.originalValuePerMonth })}
                  /mês
                </Text>
              )}
            </Flex>
          </>
        )}
        {errors && (
          <Text fontSize=".8rem" color="danger" mb={!noMarginOnError && errors?.message && "4"} role="alert">
            {errors?.message}
          </Text>
        )}
      </Flex>
    </FormControl>
  );
}
