
import { Circle, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { CheckIcon2 } from "../../../styles/icons/CheckIcon2";

interface ItemsProps {
    id: number;
    title: string;
}
interface StepComponentProps {
    section: number;
    items: Array<ItemsProps>
}

export function StepComponent({
  section, items
}: StepComponentProps) {
    const borderColors = {
        hover: "1px solid #DF295D",
        before: "1px solid #00000026",
        after: "1px solid #DF295D",
    }
    const backgroundColors = {
        hover: "pink.100",
        before: "white",
        after: "white",
    }
    const colors = {
        hover: "white",
        before: "#00000073",
        after: "pink.100",
    }
    const getStatusPosition = (position: number) => {
        if(section === position) {
            return "hover"
        }
        if(section < position) {
            return "before"
        }
        return "after"
    }

  return (
        <Flex
            w="100%"
            align="center"
        >
            {items?.map(item => (
                <React.Fragment
                    key={item.id}
                >
                    <Flex
                        align="center"
                    >
                        <Circle
                            size="32px"
                            color={colors[getStatusPosition(item?.id)]}
                            backgroundColor={backgroundColors[getStatusPosition(item?.id)]}
                            border={borderColors[getStatusPosition(item?.id)]}
                            borderRadius="full"
                        >
                            {(getStatusPosition(item.id) === "after") ? <CheckIcon2 color="#DF295D" /> : item?.id}
                        </Circle>
                        <Text
                            ml="2"
                        >
                            {item?.title}
                        </Text>
                    </Flex>
                    {item?.id !== items.length && (
                        <Divider 
                            flex={1} 
                            mx={2}
                            borderColor={(getStatusPosition(item.id) === "after")? "pink.100" : "#F3F3F3"} 
                        />
                    )}
                </React.Fragment>
            ))}
        </Flex>
  )
}