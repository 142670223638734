import { FormControl, FormLabel, Select, Text } from "@chakra-ui/react";
import { InputProps } from "../Input";
interface Props extends InputProps {
  icon?: any;
  buttonText?: string;
  tooltipButtonText?: string;
  buttonfunction?: any;
  label?: string;
  disabled?: boolean;
  buttonDisabled?: boolean;
  width?: string;
  buttonFunction?: () => void;
  color?: any;
  displayLabel?: boolean;
}

export function SelectButtonV2({
  icon,
  buttonText,
  tooltipButtonText,
  label,
  buttonfunction,
  errors,
  disabled = false,
  buttonDisabled = false,
  buttonFunction,
  width,
  color,
  register,
  labelColor = "gray.600",
  children,
  displayLabel = true,
  ...rest
}: Props) {
  return (
    <FormControl 
        maxW="md"
        isInvalid={!!errors?.message}
    >
      <FormLabel 
          w="100%"
          marginBottom=".2rem"
          fontSize=".8rem"
          color={labelColor ? labelColor : "gray.600"}
          visibility={displayLabel ? "visible" : "hidden"}
      >
          {label}
      </FormLabel>
      <Select 
        placeholder="Selecione o tipo" 
        color="gray.500" 
        borderRadius="4px"
        {...register}
        _hover={{
            cursor: "pointer"
        }}
      >
        {children}    
      </Select>
      {errors && (
        <Text color="danger" role="alert">
          {errors.message}
        </Text>
      )}
    </FormControl>
  )
}