import { NotificationMessageProps } from "@/models";
import { formatCurrency, formatDate, getInitials } from "@/utils";
import { randomColor } from "@/utils/randomColors";
import { Badge, Box, Divider, Flex, Link, Text } from "@chakra-ui/react";

interface ObjectProps {
  buyer: string;
  amount: string;
  uuid: string;
  date: string;
}

export function LatePaidNotification({
  data,
  timeDiff,
  notifiedAt = null,
}: NotificationMessageProps) {
  const object: ObjectProps = data.reduce(
    (obj: any, item: any) => Object.assign(obj, { [item.name]: item.value }),
    {}
  );

  const formattedData = {
    ...object,
    amount: formatCurrency({ value: Number(object.amount) }),
    date: formatDate(object.date, true),
  };

  return (
    <Flex flexDir="row">
      <Flex
        h="32px"
        align="center"
        marginLeft="-.5rem"
        marginRight=".5rem"
        marginTop="1rem"
      >
        <Box
          borderRadius="full"
          bgColor={notifiedAt === null ? "magenta" : "transparent"}
          h="6px"
          w="6px"
        />
      </Flex>
      <Flex flexDir="column" h="120px" marginTop="1rem">
        <Flex flexDir="row" h="120px">
          <Badge
            fontSize="1rem"
            width="32px"
            height="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            colorScheme="cyan"
            color="gray.800"
            marginRight="1rem"
          >
            {getInitials(formattedData.buyer) ?? "-"}
          </Badge>
          <Flex flexDir="column" w="100%" h="100px" justify="space-between">
            <Link
              href={`/cobrancas/${formattedData.uuid}`}
              _hover={{}}
            >
              <Text w="100%">
                <Text fontWeight="bold" display="inline">
                  {formattedData.buyer}{" "}
                </Text>
                <Text color="green.500" display="inline">
                  pagou{" "}
                </Text>
                <Text display="inline">uma cobrança atrasada </Text>
                <Text display="inline"> (venc. {formattedData.date}) de </Text>
                <Text fontWeight="bold" display="inline">
                  {formattedData.amount}
                </Text>
                <Text display="inline">. Inadimplência reduzida! 🙌</Text>
              </Text>
            </Link>
            <Text fontSize=".8rem">{timeDiff}</Text>
          </Flex>
        </Flex>
        <Divider />
      </Flex>
    </Flex>
  );
}
